/*
 * @Author: 智客云网络科技
 * @Date: 2022-05-25 11:25:03
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-05-25 11:25:07
 * @Description: 加载配置文件
 * @FilePath: \src\config\index.js
 */
//加载配置文件
const config = {
  app_version: undefined,
  app_name: undefined,
  full_name: undefined,
  abbrev_name: undefined,
  base_url: undefined,
  address: undefined,
  address_tips: undefined,
  phone: undefined,
};

//先获取当前环境
const env = process.env.NODE_ENV;

//加载默认配置文件
const default_config = require(`@/config/${env}/default_config.json`);

//加载客户配置文件
const customer_config = require(`@/config/${env}/customer_config.json`);

//合并配置文件
Object.assign(config, default_config);
Object.assign(config, customer_config);
// console.log("APPCONFIG", config);

export default config;
